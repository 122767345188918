import { memo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CereIcon = memo((props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 28 28">
    <path
      d="M14.396 28.03c-6.589 0-11.707-3.095-13.359-8.077l-.37-1.115 1.083-.506c.114-.055 2.955-1.45 2.779-4.194-.187-2.896-2.59-4.255-2.693-4.311L.78 9.244l.456-1.101C3.396 3.043 8.317 0 14.396 0c4.778 0 8.965 1.825 11.486 5.007l.636.804-5.023 6.606-1.12-1.058c-.023-.02-2.41-2.223-5.182-2.236-2.67-.012-3.723.97-3.767 1.012L9.448 8.28c.18-.188 1.876-1.863 5.757-1.835 2.473.012 4.609 1.165 5.87 2.038l1.97-2.591C21 3.84 17.908 2.679 14.396 2.679c-4.536 0-8.261 2.052-10.17 5.54 1.154.945 2.847 2.816 3.038 5.75.204 3.173-1.875 5.167-3.227 6.12 1.6 3.27 5.462 5.26 10.36 5.26 3.392 0 6.864-1.288 9.008-3.28l-2.24-2.74c-1.192.824-3.23 1.895-5.895 2.03-3.784.19-6.021-1.51-6.115-1.582l1.702-2.1c.052.038 1.572 1.145 4.27 1.006 3.194-.16 5.242-2.162 5.261-2.183l1.084-1.078.969 1.176 4.527 5.541-.694.837c-2.547 3.07-7.208 5.055-11.88 5.055Z"
      fill="url(#b)"
    />

    <defs>
      <linearGradient id="b" x1={25.829} y1={4.423} x2={4.035} y2={23.94} gradientUnits="userSpaceOnUse">
        <stop offset={0.073} stopColor="#F46881" />
        <stop offset={0.083} stopColor="#F36682" />
        <stop offset={0.26} stopColor="#E74596" />
        <stop offset={0.407} stopColor="#E031A2" />
        <stop offset={0.506} stopColor="#DD2AA6" />
        <stop offset={0.888} stopColor="#5210E2" />
      </linearGradient>
    </defs>
  </SvgIcon>
));
