import { memo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MaticIcon = memo((props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="M11.57 5.537c-.263-.15-.601-.15-.902 0L8.563 6.778l-1.43.79-2.067 1.24c-.263.15-.602.15-.902 0l-1.617-.977a.916.916 0 0 1-.451-.79v-1.88c0-.3.15-.601.45-.79l1.618-.94c.263-.15.601-.15.902 0l1.617.978c.263.15.45.452.45.79v1.24l1.43-.827V4.334c0-.3-.15-.601-.452-.79L5.104 1.778c-.264-.15-.602-.15-.903 0L1.118 3.582a.827.827 0 0 0-.451.752v3.534c0 .301.15.602.451.79l3.046 1.767c.263.15.601.15.902 0l2.068-1.203 1.429-.827 2.067-1.203c.264-.15.602-.15.903 0l1.617.94c.263.15.45.45.45.79V10.8c0 .3-.15.601-.45.79l-1.58.94c-.263.15-.601.15-.902 0l-1.617-.94a.916.916 0 0 1-.45-.79V9.598l-1.43.827v1.24c0 .302.15.602.452.79l3.045 1.767c.263.15.602.15.902 0l3.046-1.767a.917.917 0 0 0 .451-.79V8.095c0-.3-.15-.602-.451-.79L11.57 5.537Z"
      fill="#8247E5"
    />
  </SvgIcon>
));
