export const CereWhiteIcon = () => (
  <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5654_465)">
      <path
        d="M12.3558 25.7276C6.426 25.7276 1.81945 22.9419 0.333347 18.4576L0 17.4544L0.974626 16.9985C1.07769 16.9494 3.63447 15.6936 3.47543 13.2245C3.30748 10.6179 1.14447 9.39453 1.05223 9.34415L0.10179 8.81922L0.512116 7.82845C2.4569 3.23845 6.88532 0.5 12.3564 0.5C16.6563 0.5 20.425 2.14257 22.6936 5.00604L23.2662 5.72999L18.7455 11.6752L17.7365 10.723C17.7168 10.705 15.5678 8.7222 13.0734 8.711C10.6699 8.69981 9.72266 9.5836 9.68321 9.62154L7.90256 7.95222C8.06542 7.78243 9.5916 6.27544 13.0842 6.30094C15.3095 6.31089 17.2321 7.34893 18.3664 8.13445L20.1394 5.80276C18.3008 3.95556 15.5182 2.91131 12.3571 2.91131C8.27472 2.91131 4.92208 4.75788 3.20441 7.89749C4.24264 8.7477 5.76629 10.4313 5.93806 13.0727C6.12191 15.9275 4.25093 17.7225 3.03392 18.5795C4.47422 21.5232 7.94964 23.3144 12.3577 23.3144C15.4107 23.3144 18.5356 22.1545 20.4651 20.3614L18.4491 17.8954C17.3758 18.6373 15.5417 19.6014 13.1434 19.7233C9.73792 19.8943 7.72443 18.3649 7.64046 18.2996L9.17173 16.4101C9.21881 16.4437 10.5866 17.4401 13.0149 17.3151C15.8891 17.1708 17.7327 15.369 17.7499 15.3497L18.7252 14.3801L19.5974 15.438L23.6721 20.4255L23.0473 21.1786C20.7552 23.942 16.5603 25.7276 12.3558 25.7276Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5654_465">
        <rect width="23.5385" height="25.5" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
