import { memo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TransactionProgressIcon = memo((props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="30 30 180 180">
    <path
      d="M67.644 104.059 51.41 98.892l-.515 102.998H69.66l-2.016-4.507c9.075-32.541 0-93.324 0-93.324Z"
      fill="#6284FD"
    />
    <path
      d="M62.957 167.378c0-24.124-4.896-43.68-10.94-43.68a2.874 2.874 0 0 0-.731.101l-.392 78.091h7.83c2.575-7.992 4.233-20.476 4.233-34.512Z"
      fill="#131B32"
    />
    <path
      d="M62.292 105.317s3.876 63.261-7.073 93.213l2.287 3.36h-19.47s-1.9-77.464-1.9-94.831l26.156-1.742Z"
      fill="#6284FD"
    />
    <path d="M58.157 56.371v8.223L47.97 67.097V53.222l10.186-2.503v5.652Z" fill="#F5F5F7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m51.467 60.845-.057-.17-.056-.172h.003l.006-.002.022-.007.084-.025a6.172 6.172 0 0 1 1.271-.226c.666-.044 1.494.035 1.988.596a2.258 2.258 0 0 1 .594-.363c.473-.193 1.132-.25 1.856.26l-.207.294c-.61-.429-1.136-.374-1.514-.22a1.873 1.873 0 0 0-.616.415l-.001.001-.156.176-.129-.197c-.349-.534-1.075-.65-1.791-.603a5.822 5.822 0 0 0-1.272.236l-.02.006-.004.001h-.001Z"
      fill="#6284FD"
    />
    <path
      d="M52.404 59.215a.271.271 0 1 0 0-.542.271.271 0 0 0 0 .542ZM56.585 59.215a.271.271 0 1 0 0-.542.271.271 0 0 0 0 .542Z"
      fill="#131B32"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.118 56.131h2.343v.48h-1.863v3.958h-.48V56.13ZM53.993 56.611H51.89v-.48h2.103v.48Z"
      fill="#131B32"
    />
    <path d="M48.643 58.522v1.456a1.421 1.421 0 0 1-1.423-1.423v-.13c.084-.883 1.423-.791 1.423.097Z" fill="#F5F5F7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.46 58.556a1.18 1.18 0 0 0 1.183 1.182v.48a1.66 1.66 0 0 1-1.663-1.663h.48Z"
      fill="#131B32"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.496 60.797a.624.624 0 0 0 .626.626v.36a.985.985 0 0 1-.986-.987l.36.001Z"
      fill="#6284FD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m47.914 66.864 2.923-.72.115.466-2.923.72-.115-.466ZM55.592 60.514a.24.24 0 0 1-.176.29l-.92.226a.24.24 0 1 1-.114-.466l.92-.226a.24.24 0 0 1 .29.176Z"
      fill="#131B32"
    />
    <path
      d="M67.644 81.818v22.241l-31.507 7.743V87.034A19.08 19.08 0 0 1 50.66 68.506a13.71 13.71 0 0 1 16.983 13.312Z"
      fill="#F5F5F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.337 57.938v.341a16.891 16.891 0 0 0 .382 3.59c.317 1.456.224 2.656-.163 3.597a3.818 3.818 0 0 1-1.892 2.014c-1.645.8-3.865.53-5.578-.725-1.854-1.358-2.378-3.337-2.443-4.955a9.962 9.962 0 0 1 .31-2.847l.012-.045.004-.012v-.003l.001-.001.172.052.173.052-.001.003-.003.01a8.717 8.717 0 0 0-.175.794 9.594 9.594 0 0 0-.133 1.982c.063 1.558.564 3.411 2.295 4.68 1.623 1.188 3.702 1.424 5.21.691a3.457 3.457 0 0 0 1.715-1.828c.353-.858.45-1.98.145-3.382a17.255 17.255 0 0 1-.391-3.667v-.34h.36ZM49.9 50.6v-.002l.004-.004.017-.02.068-.077a5.29 5.29 0 0 1 1.295-1.014c.874-.481 2.1-.807 3.546-.196 1.433.604 2.52.73 3.264.815l.155.018c.383.046.622.086.772.172a.348.348 0 0 1 .145.145.669.669 0 0 1 .062.288l.006.18c.021.586.047 1.298-.074 1.991-.133.763-.44 1.477-1.108 1.956l.21.293c.772-.555 1.11-1.372 1.253-2.187.128-.732.1-1.483.079-2.064a42.753 42.753 0 0 1-.007-.18 1.021 1.021 0 0 0-.102-.444.708.708 0 0 0-.285-.291c-.227-.129-.546-.173-.908-.216l-.155-.018c-.738-.085-1.783-.206-3.167-.79-1.573-.664-2.917-.306-3.86.213a5.652 5.652 0 0 0-1.449 1.156c-1.308.292-2.105.908-2.544 1.688-.442.785-.506 1.708-.407 2.574.1.867.366 1.694.605 2.301a11.234 11.234 0 0 0 .447.988l.008.015.002.003v.002l.159-.086.158-.086v-.001l-.002-.003-.007-.012a5.064 5.064 0 0 1-.122-.245c-.081-.169-.191-.412-.308-.707-.233-.591-.487-1.386-.582-2.21-.095-.826-.028-1.664.362-2.356.386-.686 1.104-1.257 2.367-1.527l.063-.013.04-.05Z"
      fill="#6284FD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.035 54.502h.13a28.395 28.395 0 0 0 2.993-.166c.848-.094 1.736-.242 2.482-.477.734-.23 1.375-.558 1.67-1.042l-.307-.188c-.221.364-.747.66-1.471.887-.714.224-1.576.37-2.414.463a28.058 28.058 0 0 1-3.152.163h-.069l-.143-.003-.889 3.627.35.086.82-3.35Z"
      fill="#6284FD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m62.053 105.332.24-.015.239-.014.003.045.008.137.03.536c.025.472.062 1.166.104 2.061.085 1.79.193 4.379.29 7.58.192 6.402.334 15.25.135 25.035-.4 19.508-2.158 42.778-7.615 57.8l2.218 3.258-.397.27-2.356-3.461.042-.116c5.46-14.934 7.228-38.201 7.628-57.761.2-9.775.057-18.614-.135-25.01a444.083 444.083 0 0 0-.29-7.572 289.237 289.237 0 0 0-.134-2.592l-.008-.136-.002-.045ZM65.979 75.77l.213-.11.214-.11v.002l.003.004.008.017a10.405 10.405 0 0 1 .156.32c.105.223.255.55.432.965.356.833.823 2.027 1.276 3.476.905 2.897 1.753 6.824 1.51 10.928l-.478-.028c.237-4.025-.595-7.891-1.49-10.757a36.77 36.77 0 0 0-1.26-3.43 26.957 26.957 0 0 0-.574-1.257l-.008-.016-.002-.003v-.001ZM68.206 109.104l.088-.223.089-.223.005.002.02.007.082.03c.073.026.182.062.322.104.282.084.69.19 1.194.279 1.01.177 2.396.28 3.913-.012 3.013-.58 6.596-2.727 8.74-9.122l.455.153c-2.193 6.543-5.906 8.825-9.105 9.44-1.589.306-3.037.197-4.086.014a11.193 11.193 0 0 1-1.248-.292 7.18 7.18 0 0 1-.46-.153l-.006-.003-.002-.001ZM73.133 91.332l-.11-.214-.11-.213.008-.004.023-.012.093-.047.35-.175c.301-.15.734-.36 1.258-.606a58.102 58.102 0 0 1 3.935-1.679c1.468-.556 3.001-1.044 4.28-1.243.638-.1 1.227-.13 1.719-.052.491.077.916.266 1.177.633l1.68 2.352-.391.279-1.68-2.352c-.157-.222-.437-.372-.86-.438s-.956-.044-1.572.052c-1.229.192-2.725.666-4.183 1.218a57.577 57.577 0 0 0-3.902 1.665 64.057 64.057 0 0 0-1.685.82l-.023.012-.007.004ZM104.41 48.528h70.168v.48H104.89v44.511l-22.486 22.486v86.125h-9.382v-.48h8.902v-85.844l22.486-22.485V48.528ZM203.863 202.13H84.646v-.48h119.217v.48Z"
      fill="#131B32"
    />
    <path
      d="M43.075 72.48s-5.28 20.894 1.11 28.702c6.388 7.807 36.35 3.199 45.054-5.25 0 0 3.072-.767 3.713-2.303 0 0 2.942 2.177 2.815.768-.127-1.409-1.152-5.796-6.4-4.69-5.25 1.107-30.72 1.618-30.72 1.618l.573-11.007"
      stroke="#131B32"
      fill="transparent"
      strokeWidth={0.5}
      strokeMiterlimit={10}
    />
    <path
      d="M189.763 200.952s2.055-3.634-3.161-4.423a17.075 17.075 0 0 0-9.165 1.106s3.636-4.425-2.055-6.321c-5.69-1.896-11.534 2.527-11.534 2.527s5.688-10.587 1.265-11.376c-4.423-.79-6.795 3.792-6.795 3.792s.48-13.116-8.69-13.44c-9.17-.324-8.218 16.749-8.218 16.749s-3.95-7.576-10.586-7.886c-6.636-.31-3.319 12.706-3.319 12.706s-1.265-3.068-5.688-3.384c-4.423-.317-4.109 3.398-4.109 3.398s-5.688-7.975-12-7.502c-6.312.472-3.634 8.376-3.634 8.376-6.48-.48-7.9 6.626-7.9 6.626h100.171l-4.582-.948ZM107.683 124.025h76.815v44.858h-76.815v-44.858Z"
      fill="#131B32"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.338 158.748V114.13h.48v45.098l-77.054-.048v-.48l76.574.048Z"
      fill="#131B32"
    />
    <path d="M97.764 114.106h76.815v44.858H97.764v-44.858Z" fill="#F5F5F7" />
    <path
      d="M136.171 150.653c7.798 0 14.119-6.321 14.119-14.119s-6.321-14.119-14.119-14.119-14.119 6.321-14.119 14.119 6.321 14.119 14.119 14.119Z"
      fill="#6284FD"
    />
    <path
      d="M136.171 136.534a4.01 4.01 0 0 1-3.701-2.474 4.002 4.002 0 0 1 .87-4.365 4.002 4.002 0 0 1 4.366-.867 4.001 4.001 0 0 1 2.471 3.702M136.171 136.534a4 4 0 0 1 3.701 2.472 4.006 4.006 0 1 1-7.706 1.533M136.171 126.223v20.621"
      stroke="#F5F5F7"
      fill="transparent"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M106.423 153.218a2.88 2.88 0 1 0 .001-5.759 2.88 2.88 0 0 0-.001 5.759ZM165.919 125.611a2.88 2.88 0 1 0 .001-5.759 2.88 2.88 0 0 0-.001 5.759ZM165.919 153.218a2.88 2.88 0 1 0 .001-5.759 2.88 2.88 0 0 0-.001 5.759ZM106.423 125.611a2.88 2.88 0 1 0 .001-5.759 2.88 2.88 0 0 0-.001 5.759Z"
      fill="#E7E8EB"
    />
  </SvgIcon>
));
