import { memo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const UsdcIcon = memo((props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 506.76 506.76">
    <defs>
      <style>{'.cls-2{fill:#fff}'}</style>
    </defs>
    <title>{'usd-coin'}</title>
    <path
      d="M253.38 506.76A253.38 253.38 0 1 0 0 253.38a252.77 252.77 0 0 0 253.38 253.38Z"
      style={{
        fill: '#2775ca',
      }}
    />
    <path
      className="cls-2"
      d="M323.06 293.5c0-36.95-22.17-49.62-66.51-54.9-31.67-4.22-38-12.67-38-27.45s10.56-24.28 31.67-24.28c19 0 29.57 6.33 34.84 22.17a8 8 0 0 0 7.4 5.28h16.89a7.22 7.22 0 0 0 7.39-7.39v-1.06a52.74 52.74 0 0 0-47.51-43.28v-25.34c0-4.22-3.17-7.39-8.45-8.45h-15.84c-4.23 0-7.39 3.17-8.45 8.45v24.28c-31.67 4.22-51.73 25.34-51.73 51.73 0 34.84 21.11 48.57 65.45 53.85 29.57 5.27 39.07 11.61 39.07 28.5s-14.78 28.51-34.84 28.51c-27.45 0-37-11.62-40.12-27.45-1.06-4.22-4.22-6.34-7.39-6.34H189a7.22 7.22 0 0 0-7.39 7.39v1.06c4.22 26.39 21.12 45.4 56 50.68v25.33c0 4.23 3.16 7.39 8.44 8.45h15.84c4.22 0 7.39-3.17 8.44-8.45v-25.33c31.68-5.28 52.79-27.46 52.79-56Z"
    />
    <path
      className="cls-2"
      d="M199.54 404.35c-82.35-29.56-124.58-121.41-94-202.7 15.83-44.34 50.67-78.13 94-94 4.22-2.11 6.33-5.28 6.33-10.56V82.35c0-4.22-2.11-7.39-6.33-8.45-1.06 0-3.17 0-4.23 1.06C95 106.63 40.12 213.26 71.79 313.56a189.91 189.91 0 0 0 123.52 123.52c4.23 2.11 8.45 0 9.51-4.22 1.05-1.06 1.05-2.11 1.05-4.22v-14.78c0-3.17-3.16-7.39-6.33-9.51ZM311.45 75c-4.23-2.11-8.45 0-9.5 4.22-1.06 1.06-1.06 2.11-1.06 4.23v14.74c0 4.22 3.17 8.44 6.33 10.55 82.35 29.56 124.58 121.41 94 202.71-15.84 44.34-50.68 78.12-94 94-4.22 2.11-6.33 5.28-6.33 10.56v14.78c0 4.22 2.11 7.39 6.33 8.44 1.06 0 3.17 0 4.23-1.05C411.74 406.47 466.64 299.83 435 199.54A191.47 191.47 0 0 0 311.45 75Z"
    />
  </SvgIcon>
));
